@import "../rgbstyle/rgbstyle-mixins";

.el-home-slides {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
        padding: 0;
    }

    .lista-itens {
        display: flex;
        overflow: hidden;

        .item {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;

            a {
                display: block;
                position: relative;

                picture {
                    display: block;
                    aspect-ratio: 0.86 / 1;

                    @include desktop {
                        aspect-ratio: 3.11 / 1;
                    }

                    source,
                    img {
                        display: block;
                        position: relative;
                        max-width: 100%;
                        height: auto;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .slick-arrow {
        display: none;

        @include desktop {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            width: 58px;
            height: 58px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #757575;
            background-color: #fff;
            opacity: .6;

            svg {
                width: 18px;
            }

            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}
